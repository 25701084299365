import { PlatformLocation } from '@angular/common';

import { Component, HostListener, Renderer2 } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { AuthenticationResult, EventMessage, EventType, InteractionStatus, RedirectRequest } from '@azure/msal-browser';
import { AngularPlugin } from '@microsoft/applicationinsights-angularplugin-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { filter, map, take } from 'rxjs';
import { environment } from 'src/environments/environment';
import { silentRequest } from './auth-config';
import { Claims } from './models/accounts/token';
import { AzureExceptionDetails } from './models/common/exception';
import { LoadHomepageData } from './module/home/store/action/home.actions';
import { AuthService } from './services/auth/auth.service';
import { ConfigurationService } from './services/config/configuration.service';
import * as fromAction from './store/actions/app.action';
import { selectApplicationConfiguration } from './store/selectors/app.selector';
import { ApplicationConfigurationState } from './store/state/app.state';
import { SaveLoyaltyPoints } from './state/app.state';

declare const gtag: Function;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Bizligo';
  loggedIn: boolean;
  showNavigationLoading: boolean = false;
  applicationNotLoaded: boolean = true;
  currentUrl: string;
  dot: string = "";
  userClaims: Claims;
  dotTimer: NodeJS.Timeout;
  disableScrollonNavigation: string[] = [
    '/community/{0}/{0}',
    '/community/{0}/{0}/discussions',
    '/community/{0}/{0}/members',
    '/community/{0}/{0}/groups',
    '/community/{0}/{0}/events',
    '/community/{0}/{0}/resources',
    '/community/{0}/{0}/careers',
    '/group/{0}/{0}/discussions',
    '/group/{0}/{0}/members',
    '/group/{0}/{0}/events',
    '/group/{0}/{0}/resources',
    '/group/{0}/{0}/about-group',
    '/group/{0}/{0}'
  ];


  constructor(private msalService: MsalService,
    private broadcastService: MsalBroadcastService,
    location: PlatformLocation,
    private spinner: NgxSpinnerService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private configurationService: ConfigurationService,
    private store: Store<ApplicationConfigurationState>,
    private renderer: Renderer2,
    private matSnackBar: MatSnackBar,
    private authService: AuthService,
    private translateService: TranslateService) {

    let body = document.querySelector("body");
    this.renderer.setAttribute(body, "app-version", environment.appVersion);

    this.msalService.instance.enableAccountStorageEvents();

    const browserCultureLang = this.translateService.getBrowserCultureLang();
    const BrowserLang = this.translateService.getBrowserLang();

    translateService.setDefaultLang('en');
    translateService.use('en');

    //console.log("current lang in APP module ", this.translateService.currentLang);
    //console.log("BrowserLang", BrowserLang);

    let needToLogin = localStorage.getItem('loginRedirect');
    if (needToLogin && needToLogin == '1') {
      localStorage.removeItem('loginRedirect');
      this.authService.loginRedirect();
    }


    this.store.dispatch(fromAction.LoadTenantId());
    //this.store.dispatch(fromAction.SideNavClose());
    this.store.dispatch(fromAction.LoadTenantFeatureConfiguration());
    this.store.dispatch(fromAction.LoadBusinessType());
    this.store.dispatch(fromAction.LoadClientConfiguration());
    this.store.dispatch(fromAction.LoadTenantLogo());
    this.store.dispatch(fromAction.LoadUserStatus());
    this.store.dispatch(LoadHomepageData());
    this.store.dispatch(fromAction.LoadAvilableLanguages());
    this.store.dispatch(fromAction.LoadCommunityFeatureConfiguration());

    this.msalService.handleRedirectObservable().subscribe({
      next: (result: AuthenticationResult) => {
        if (result != null) {
          console.log('login');
          this.store.dispatch(fromAction.userLoggedInRedirectionn());
          var loyaltyData: SaveLoyaltyPoints = {
            refId: 0,
            keyname: 'LOGIN'
          }
          this.authService.saveLoyaltyPoints(loyaltyData).subscribe({
            next: async (data) => {


            },
            error: (err) => {



            }
          })
        }
      },
      error: (error: AzureExceptionDetails) => {
        if (error.errorMessage.includes("AADB2C90091")) {
          this.matSnackBar.open("The user has cancelled the operation", '', { duration: 3000, panelClass: ['text-warning'] });
        }
        if (error.errorMessage.includes("AADB2C99002")) {
          let action = this.matSnackBar.open("User does not exist. Please sign up before you can sign in", 'Sign Up', { duration: 30000, panelClass: ['text-warning'] });
          action.onAction().subscribe(() => {
            this.router.navigateByUrl("/auth/signup");
          })
        }

        if (error.errorMessage.includes("API12345")) {
          let action = this.matSnackBar.open("User is not found, please signup to continue", 'Sign Up', { duration: 30000, panelClass: ['text-warning'] });
          action.onAction().subscribe(() => {
            this.router.navigateByUrl("/auth/signup");
          })
        }

        if (error.errorMessage.includes("AADB2C90118")) {
          this.matSnackBar.open("Password Reset", '', { duration: 10000 });
          //B2C_1_resetpwd
          this.msalService.loginRedirect({
            scopes: silentRequest.scopes,
            authority: environment.b2cPolicies.resetPassword.authority
          })
        }

        // console.log(error.correlationId)
        // console.log(error.errorCode)
        // console.log(error.errorMessage)
        // console.log(error.name)
        // console.log(error.subError)
        // console.log(error)
      }
    });

    this.broadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS)
      )
      .subscribe((result: EventMessage) => {
        const payload = result.payload as AuthenticationResult;
        this.msalService.instance.setActiveAccount(payload.account);
      });

    this.broadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        take(1)
      )
      .subscribe((s) => {

        this.loggedIn = this.msalService.instance.getAllAccounts().length > 0;
        if (this.loggedIn) {
          let claims = this.msalService.instance.getActiveAccount().idTokenClaims;
          this.userClaims = JSON.parse(JSON.stringify(claims)) as Claims;
          var token = this.msalService.acquireTokenSilent(
            {
              scopes: silentRequest.scopes,
              authority: `${environment.b2cAuthorityUrl}/${this.userClaims.tfp}`
            })
            .subscribe({
              next: (s) => {
                // let claims = this.msalService.instance.getActiveAccount().idTokenClaims;
                // this.userClaims = JSON.parse(JSON.stringify(claims)) as Claims;
                //this.idle.start();
                this.startSession();
                //console.log("Token Details => ", s);

                if (this.userClaims.isForgotPassword && this.userClaims.isForgotPassword == true) {

                  this.matSnackBar.open("Forgot password successful, please login to continue");
                  this.authService.updateUserUsedForgotPassword(this.userClaims.email).subscribe({
                    next: () => {
                      this.msalService.logoutPopup();
                      this.authService.loginRedirect();
                    },
                    error: (err) => {

                      this.msalService.logoutPopup();
                      this.authService.loginRedirect();
                    }
                  })

                  return;
                }
                if (this.userClaims.tfp == "B2C_1_resetpwd") {

                  this.msalService.logoutPopup();
                  this.matSnackBar.open("Password reset successful, please login to continue");
                  return;
                }
                if (this.userClaims.extension_BzTenantId == "" || this.userClaims.extension_BzUserId == 0) {
                  this.router.navigate(['/auth/signup']);
                  // if (this.userClaims.tfp == "B2C_1_signup") {
                  //   this.router.navigate(['/auth/signup']);
                  //   //return;
                  // } else {
                  //   alert("You are not belongs to this tenant");
                  // }
                }

              },
              error: (error: AzureExceptionDetails) => {
                console.log("Error => ", error);
                if (error.errorMessage.includes("AADB2C90077")) {

                  this.matSnackBar.open("Session Expired", '', { duration: 5000 });
                  this.msalService.logoutRedirect();
                }
                if (error.errorMessage.includes("AADB2C90129")) {

                  this.matSnackBar.open("Session Expired, Please reauthenticate and try again", '', { duration: 5000 });
                  this.msalService.logoutRedirect();
                }

                //this.msalService.logoutPopup()
              }
            });
        }

        // this.dataService.getMenu()
        //   .pipe(map(s => {
        //     return s.menus
        //   }))
        //   .subscribe(data => {
        //     //console.log(data)
        //     this.menuItems = data
        //   })

      });

    var angularPlugin = new AngularPlugin();
    const appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: environment.appInsights.instrumentationKey,
        extensions: [angularPlugin],
        extensionConfig: {
          [angularPlugin.identifier]: { router: this.router }
        }
      }
    });
    appInsights.loadAppInsights();

    this.dotTimer = setInterval(() => {
      if (this.dot.length == 5) {
        this.dot = ".";
      } else {
        this.dot = this.dot + ".";
      }
    }, 200);

    this.router.events.subscribe(routerEvent => {
      if (routerEvent instanceof NavigationStart) {
        this.spinner.show();
        this.showNavigationLoading = true;
        location.onPopState(() => {
          //window.location.reload();
        });
        this.currentUrl = routerEvent.url.substring(
          routerEvent.url.lastIndexOf('/') + 1
        );
      }
      if (routerEvent instanceof NavigationEnd || routerEvent instanceof NavigationCancel || routerEvent instanceof NavigationError) {
        this.spinner.hide();
        this.showNavigationLoading = false;
        if (this.applicationNotLoaded) {
          clearInterval(this.dotTimer);
          this.applicationNotLoaded = false;
        }
      }


      if (routerEvent instanceof NavigationEnd) {
        let currentUrlSegment = routerEvent.url.split('/').filter(s => s != '');
        
        let stopScroll = this.disableScrollonNavigation.filter(s => {
          let matchingSegment = s.split('/').filter(s => s != '' && s != '{0}');

          let matching = matchingSegment.filter(o => currentUrlSegment.indexOf(o) !== -1);
          return JSON.stringify(matchingSegment) === JSON.stringify(matching);
        }).length;

        if (stopScroll == 0) {
          window.scrollTo(0, 0);
        } else {
          if (routerEvent.url.toLowerCase().startsWith("/admin")) {
            window.scrollTo(0, 0);
          }
          // let scrollDistance = (window.innerHeight * .7) + 50;
          // setTimeout(() => {
          //   window.scrollTo(0, scrollDistance);
          // }, 300);
        }
        gtag('event', 'page_view', {
          page_path: routerEvent.urlAfterRedirects
        })
      }
    });

    this.authService.getAccessToken2().pipe(take(1)).subscribe(token => {
      if (token == null) {
        var SESSION_HAS_EXPIRED = localStorage.getItem(this.SESSION_HAS_EXPIRED);
        if (SESSION_HAS_EXPIRED === "1") {
          this.matSnackBar.open("Your session has expired", "OK");
          setTimeout(() => {
            localStorage.removeItem(this.SESSION_HAS_EXPIRED);
          }, 5000)
        }
      }
    })

    configurationService.getGoogleAnalyticsTraackingId().subscribe({
      next: (trackingId) => {
        if (trackingId) {
          this.addGAScript(trackingId);

          // gtag('event', 'view_item', {
          //   'event_category': 'engagement',
          //   'event_label':'test',
          //   'value': 1
          // });
        }
      },
      error: (err) => {
        console.error("Unable to load Google Analytics Tracking Id")
      }
    })

    this.store.select(selectApplicationConfiguration).pipe(
      map(config => config.tenant),
      filter(tenantId => tenantId != null),
      take(1),
    ).subscribe(tenant => {
      let tenantId = tenant.tenantId;
      if (!environment.production) {
        tenantId = this.authService.getCurrentTenant();;
      }
      var manifestElement = document.querySelector("#manifest");
      const url = `${environment.apiUrl}/api/common/getmenifest/manifest.json?tenant=${tenantId}`;
      this.renderer.setProperty(manifestElement, 'href', url);
      this.configurationService.getMenifest().subscribe({
        next: (data) => {
          var theme = document.querySelector('[name="theme-color"]');
          this.renderer.setProperty(theme, 'content', data.theme_color);
        },
        error: (err) => {
          this.matSnackBar.open("Unable to load Menifest Information", '', { duration: 5000 });
        }
      })
    })


  }

  sessionTimeout: string | number | NodeJS.Timeout;
  maxIdleTime = environment.sessionTimeout * 60 * 1000; // 15 minutes in milliseconds
  //maxIdleTime = .5 * 60 * 1000; // 15 minutes in milliseconds
  SESSION_KEY = 'BIZLIGO_SESSION';
  SESSION_HAS_EXPIRED = 'SESSION_HAS_EXPIRED';
  SESSION_STARTED = false;
  ref = this;

  resetSessionTimeout() {
    if (this.SESSION_STARTED) {

      if (this.sessionTimeout) {
        clearTimeout(this.sessionTimeout);
      }
      this.sessionTimeout = setTimeout(this.logout, this.maxIdleTime, this);
    } else {
      //console.log("session is not started");
    }
  }

  logout(ref: AppComponent) {

    if (ref.SESSION_STARTED) {
      if (ref.sessionTimeout) {
        clearTimeout(ref.sessionTimeout);
      }
      localStorage.removeItem(ref.SESSION_KEY);
      ref.SESSION_STARTED = false;
      ref.msalService.logoutRedirect({ postLogoutRedirectUri: ref.router.url });
      //SESSION_HAS_EXPIRED
      localStorage.setItem(ref.SESSION_HAS_EXPIRED, '1');
    }

    //console.log("session time out caalled", ref.SESSION_STARTED)
  }

  handleActivity() {
    if (this.SESSION_STARTED) {
      this.resetSessionTimeout();
      // Update the session key in localStorage
      let time = Date.now();
      //console.log("Updating in localstoragae => ", time);
      localStorage.setItem(this.SESSION_KEY, time.toString());
    }
  }

  checkSession() {
    if (this.SESSION_STARTED) {
      const lastActivity = parseInt(localStorage.getItem(this.SESSION_KEY));
      if (lastActivity && Date.now() - lastActivity <= this.maxIdleTime) {
        // The session is still active, reset the timeout and return
        this.resetSessionTimeout();
        return;
      }
      // The session has expired, perform logout

      this.logout(this);
    }
  }

  startSession() {
    //console.log("Session started ", Date.now());
    this.SESSION_STARTED = true;
    this.handleActivity();
    this.checkSession();
  }

  @HostListener("window:focus", ['$event'])
  @HostListener("window:mousemove", ['$event'])
  @HostListener("window:mousedown", ['$event'])
  @HostListener("window:keypress", ['$event'])
  onActiveUsage(event: Event): void {
    if (this.SESSION_STARTED) {
      this.handleActivity();
    }
  }

  @HostListener("window:storage", ['$event'])
  onStorageEvent(event: StorageEvent): void {
    if (event.key === this.SESSION_KEY) {

      //console.log("Updating session from other tab")

      this.checkSession();
    }
  }

  addGAScript(googleAnalyticsTrackingId: string) {
    let gtagScript: HTMLScriptElement = document.createElement('script');
    gtagScript.async = true;
    gtagScript.src = `https://www.googletagmanager.com/gtag/js?id=${googleAnalyticsTrackingId}`;
    document.head.prepend(gtagScript);
    /** Disable automatic page view hit to fix duplicate page view count  **/

    gtag('config', googleAnalyticsTrackingId, { send_page_view: false });
  }

}
