import { DOCUMENT } from '@angular/common';
import { Component, ElementRef, HostListener, Inject, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, NavigationEnd, Router, RouterStateSnapshot } from '@angular/router';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { InteractionStatus } from '@azure/msal-browser';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { select, Store } from '@ngrx/store';
import { filter, map, Observable, tap, switchMap, distinctUntilChanged, pairwise, startWith } from 'rxjs';
import { silentRequest } from 'src/app/auth-config';
import { ConfigService } from 'src/app/config/config.service';
import { InConfiguration } from 'src/app/core/models/config.interface';
import { WINDOW } from 'src/app/core/service/window.service';
import { Claims } from 'src/app/models/accounts/token';
import { TenantLogo } from 'src/app/models/configuration/configuration';
import { LoadCommunityDiscussionsData } from 'src/app/module/community-details/store/action/community-discussions.actions';
import { CommunityDiscussionSearch } from 'src/app/module/community-details/store/state/community-discussions';
import { LoadGroupDiscussionsData } from 'src/app/module/group-details/store/action/group-discussion.actions';
import { GroupDiscussionSearch } from 'src/app/module/group-details/store/state/group-discussion';
import { MyprofileService } from 'src/app/module/my-profile/services/myprofile.service';
import { LoadUserInfo } from 'src/app/module/my-profile/store/action/profile.actions';
import { ISearchAdditionalInfo, ProfileState, UserMembershipPlansStatus } from 'src/app/module/my-profile/store/state/profile';
import { SlugPipe } from 'src/app/pipe/slug.pipe';
import { AuthService } from 'src/app/services/auth/auth.service';
import { CommunityService } from 'src/app/services/community/community.service';
import { ChangeAdminCommunityLogo, LoadCurrentUserInfo, LoadMembershipStatuses, SideNavClose, SideNavOpen, UpdateCommunityLogo } from 'src/app/store/actions/app.action';
import { loadMembershipStatussesData, selectApplicationConfiguration, selectCommonApplicationState, SelectTenantLogo } from 'src/app/store/selectors/app.selector';
import { environment } from 'src/environments/environment';
import { UnsubscribeOnDestroyAdapter } from '../../shared/UnsubscribeOnDestroyAdapter';
import { LoadNotifications, LoadProfileSettings } from '../store/action/layout.actions';

import { loadNotificationsdata, loadProfileSettingsdata } from '../store/selector/layout.selectors';
import { IProfileSettingsSearch, Notificationsstate, ProfileSettingsstate, UserTaggedNotificationDto } from '../store/state/layout';
import { AdminConfirmationDialogComponent } from 'src/app/admin/module/admin-manage-events/pages/admin-confirmation-dialog/admin-confirmation-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import moment from 'moment';
//import { DataService, Menu } from './../../../services/data/data.service';
const document: any = window.document;
@Component({
  selector: 'bzg-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent extends UnsubscribeOnDestroyAdapter implements OnInit, OnDestroy {
  @ViewChild('myDrop') myDrop: NgbDropdown;
  data$: Observable<ProfileSettingsstate>;
  notificationsdata$: Observable<Notificationsstate>;
  loggedIn: boolean = false;
  public config: InConfiguration;
  isNavbarCollapsed = true;
  isNavbarShow = true;
  flagvalue: any;
  countryName: any;
  langStoreValue: string;
  defaultFlag: string;
  isOpenSidebar: boolean;
  currentUser: Claims;
  avatarUrl: string;
  showProfileSettings: boolean = false;
  userData: any;
  isSignUpEnabled: Observable<boolean>;
  currentPageName: Observable<string>;
  pdata$: Observable<ProfileState>;
  maxLengthForName: number = 25;
  isLoyaltyEnabled: Observable<boolean>;
  profileUpdating: boolean = false;
  phoneStatus: number = null;
  emailStatus: number = null;
  notificationData: UserTaggedNotificationDto[];
  notificationCount: number;
  myDate = new Date();
  communityLogoUrl: string;
  link: string;
  showNotification: boolean = false;
  collerUrl: Observable<string>;
  tenantLogo: Observable<TenantLogo>;
  isTA: boolean = false;
  tAScreen: boolean = false;
  userMembershipPlansStatus: UserMembershipPlansStatus[];
  isPlansExpiredOrAboutToExpire: boolean;
  renewMessage: string;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(WINDOW) private window: Window,
    private renderer: Renderer2,
    public elementRef: ElementRef,
    private configService: ConfigService,
    private router: Router,
    private authService: AuthService,
    private msalService: MsalService,
    private auth: AuthService,
    private broadcastService: MsalBroadcastService,
    private store: Store,
    private headerstore: Store<ProfileSettingsstate>,
    private profileStore: Store<ProfileState>,
    private profileService: MyprofileService,
    private matSnackBar: MatSnackBar,
    private route: ActivatedRoute,

    private communityService: CommunityService,
    private slug: SlugPipe,
    private dialog: MatDialog
  ) {
    super();
    this.config = this.configService.configData;
    this.isLoyaltyEnabled = this.store.select(selectApplicationConfiguration).pipe(
      filter(s => s.tenantFeatureConfiguration != null),
      map(config => config.tenantFeatureConfiguration),
      map(config => config.find(s => s.keyName == "LOYALITY")),
      map(loyalityFeature => loyalityFeature.enable)
    );


  }


  listLang = [
    { text: 'English', flag: 'assets/images/flags/us.jpg', lang: 'en' },
    { text: 'Spanish', flag: 'assets/images/flags/spain.jpg', lang: 'es' },
    { text: 'German', flag: 'assets/images/flags/germany.jpg', lang: 'de' }
  ];
  notifications: any[] = [
    {
      userImg: 'assets/images/user/user1.jpg',
      userName: 'Sarah Smith',
      time: '14 mins ago',
      message: 'Please check your mail'
    },
    {
      userImg: 'assets/images/user/user2.jpg',
      userName: 'Airi Satou',
      time: '22 mins ago',
      message: 'Work Completed !!!'
    },
    {
      userImg: 'assets/images/user/user3.jpg',
      userName: 'John Doe',
      time: '3 hours ago',
      message: 'kindly help me for code.'
    },
    {
      userImg: 'assets/images/user/user4.jpg',
      userName: 'Ashton Cox',
      time: '5 hours ago',
      message: 'Lets break for lunch...'
    },
    {
      userImg: 'assets/images/user/user5.jpg',
      userName: 'Sarah Smith',
      time: '14 mins ago',
      message: 'Please check your mail'
    },
    {
      userImg: 'assets/images/user/user6.jpg',
      userName: 'Airi Satou',
      time: '22 mins ago',
      message: 'Work Completed !!!'
    },
    {
      userImg: 'assets/images/user/user7.jpg',
      userName: 'John Doe',
      time: '3 hours ago',
      message: 'kindly help me for code.'
    }
  ];

  @HostListener('window:scroll', [])
  onWindowScroll() {
    const offset =
      this.window.pageYOffset ||
      this.document.documentElement.scrollTop ||
      this.document.body.scrollTop ||
      0;
    // if (offset > 50) {
    //   this.isNavbarShow = true;
    // } else {
    //   this.isNavbarShow = false;
    // }
  }

  async getUserProfileData() {
    if (this.userData == null) {
      let cUser = await this.authService.getCurrentUser();


      let query: ISearchAdditionalInfo = {
        userId: cUser?.extension_BzUserId
      };
      await this.store.dispatch(LoadCurrentUserInfo(query));
      this.profileService.GetUserInfo(query).subscribe({
        next: (pdata) => {
          this.userData = pdata;
          localStorage.setItem('currentUser', JSON.stringify(pdata));
          //console.log("User Info",this.userData);
        },
        error: (err) => {
          this.userData = null;
          //this.noOfEmployeeListHasError = true;
          this.matSnackBar.open('Unable to fetch user data', 'Close', { duration: 3000 });
        }
      })
    }
  }
  openSideMenu() {
    this.renderer.removeClass(this.document.body, 'side-closed');
    this.renderer.removeClass(this.document.body, 'submenu-closed');
  }

  closeSideMenu() {
    this.renderer.addClass(this.document.body, 'side-closed');
    this.renderer.addClass(this.document.body, 'submenu-closed');
  }

  ngOnInit() {
    setTimeout(async () => {
      let cUser = await this.authService.getCurrentUser();
      //console.log(cUser.extension_Role,'current role');
      if (cUser) {
        this.isTA = cUser.extension_Role == 'EVENTMANAGER';
        let currentUrl = this.router.url;
        if (this.isTA) {
          if (currentUrl.startsWith('/admin')) {
            this.tAScreen = true;
          } else {
            this.tAScreen = false;
          }
        }
      }
      //console.log(this.router.url,'url');
      if (cUser) {

        let query: IProfileSettingsSearch = {
          userId: cUser?.extension_BzUserId
        };
        this.headerstore.dispatch(LoadProfileSettings(query));
        this.headerstore.dispatch(LoadNotifications());
      }
    }, 0);

    this.tenantLogo = this.store.select(SelectTenantLogo).pipe(
      distinctUntilChanged(),
      tap(logo => {
        let favicon = document.querySelector("link[rel='icon']");
        if (favicon != null) {
          favicon.setAttribute('href', logo.favicon);
        }
      })
    );

    this.collerUrl = this.store.select(selectApplicationConfiguration).pipe(
      filter(config => config.tenant != null),
      map(config => config.tenant),
      switchMap(tenant => {
        return this.authService.getCurrentUser2().pipe(
          switchMap(user => this.authService.getAccessToken2().pipe(
            map(accessToken => {
              if (accessToken && user && tenant) {
                let link = `https://${tenant.tenantId.toLowerCase()}.collear.com/login/index.php?AccessToken=${accessToken}&UserID=${user.extension_BzUserId}&SubDomain=${window.location.host}&tenantName=${tenant.tenantId}&endpoint=${environment.apiUrl}`;
                return link;
              } else {
                return null;
              }
            })
          ))
        )
      })
    )

    this.data$ = this.headerstore.pipe(select(loadProfileSettingsdata));
    // this.notificationsdata$ = this.headerstore.pipe(select(loadNotificationsdata));

    this.headerstore.select(loadNotificationsdata).subscribe((data) => {
      //console.log(data.notifications?.filter(s=>s.flagId == 1));
      let datanot = data.notifications?.filter(s => s.flagId == 1);
      this.notificationCount = datanot?.length;
      this.notificationData = data.notifications;
    })
    // console.log(this.data$);

    this.subs.add(this.communityService.communityId
      .pipe(
        startWith(0),
        pairwise(), // Emits [previous, current]
        distinctUntilChanged((prev, curr) => prev[1] === curr[1]),

      ).subscribe(s => {
        //console.log("community Id => ", s);
        if (s[1] > 0) {
          //console.log("updating community logo", s[1]);
          this.store.dispatch(ChangeAdminCommunityLogo({ communityId: s[1] }));
        } else {
          this.store.dispatch(UpdateCommunityLogo({ link: '/', logoUrl: null }));
        }
      }));

    //var data = await this.profileStore.dispatch(LoadUserInfo());

    //  this.profileStore
    //    .select(loadskills)
    //    .subscribe((pdata) => {

    //      this.userData = pdata.userInfo?.userInfoList;
    //      console.log(this.userData);

    //    });

    this.currentPageName = this.store.select(selectCommonApplicationState).pipe(

      tap(s => {
        this.communityLogoUrl = s.communityLogoUrl;
        this.link = s.link;
        if (this.router.url.toLowerCase().includes('/profile/myprofile')) {
          this.showProfileSettings = true
        } else {
          this.showProfileSettings = false;
        }

        if (s.currentPageName == 'Discussions') {
          this.showNotification = true;
        } else {
          this.showNotification = false;
        }

        if (s.sideNavIsOpen) {
          this.openSideMenu();
        } else {
          this.closeSideMenu();
        }
      }),
      map(s => s.currentPageName)
    );


    this.isSignUpEnabled = this.store.select(selectApplicationConfiguration).pipe(
      filter(s => s.tenantFeatureConfiguration != null),
      map(config => config.tenantFeatureConfiguration),
      map(config => config.find(s => s.keyName == "SIGNUP")),
      map(signUpFeature => signUpFeature.enable)
    )


    this.avatarUrl = "https://i.pravatar.cc/150?img=01";
    this.avatarUrl = null;

    this.broadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
      )
      .subscribe((s) => {
        this.loggedIn = this.msalService.instance.getAllAccounts().length > 0;
        if (this.loggedIn) {
          let claims = this.msalService.instance.getActiveAccount().idTokenClaims;
          this.currentUser = JSON.parse(JSON.stringify(claims)) as Claims;
          this.getUserProfileData();
        }
      })

    this.langStoreValue = localStorage.getItem('lang');
    const val = this.listLang.filter((x) => x.lang === this.langStoreValue);
    this.countryName = val.map((element) => element.text);
    if (val.length === 0) {
      if (this.flagvalue === undefined) {
        this.defaultFlag = 'assets/images/flags/us.jpg';
      }
    } else {
      this.flagvalue = val.map((element) => element.flag);
    }
    this.store.dispatch(LoadMembershipStatuses());
    this.getUserMembershipStatuses();
  }

  ngAfterViewInit() {
    // set theme on startup
    if (localStorage.getItem('theme')) {
      this.renderer.removeClass(this.document.body, this.config.layout.variant);
      this.renderer.addClass(this.document.body, localStorage.getItem('theme'));
    } else {
      this.renderer.addClass(this.document.body, this.config.layout.variant);
    }

    if (localStorage.getItem('menuOption')) {
      this.renderer.addClass(
        this.document.body,
        localStorage.getItem('menuOption')
      );
    } else {
      this.renderer.addClass(
        this.document.body,
        'menu_' + this.config.layout.sidebar.backgroundColor
      );
    }

    if (localStorage.getItem('choose_logoheader')) {
      this.renderer.addClass(
        this.document.body,
        localStorage.getItem('choose_logoheader')
      );
    } else {
      this.renderer.addClass(
        this.document.body,
        'logo-' + this.config.layout.logo_bg_color
      );
    }

    if (localStorage.getItem('sidebar_status')) {
      if (localStorage.getItem('sidebar_status') === 'close') {
        this.renderer.addClass(this.document.body, 'side-closed');
        this.renderer.addClass(this.document.body, 'submenu-closed');
      } else {
        this.renderer.removeClass(this.document.body, 'side-closed');
        this.renderer.removeClass(this.document.body, 'submenu-closed');
      }
    } else {
      if (this.config.layout.sidebar.collapsed === true) {
        this.renderer.addClass(this.document.body, 'side-closed');
        this.renderer.addClass(this.document.body, 'submenu-closed');
      }
    }
  }

  callFullscreen() {
    if (
      !document.fullscreenElement &&
      !document.mozFullScreenElement &&
      !document.webkitFullscreenElement &&
      !document.msFullscreenElement
    ) {
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.msRequestFullscreen) {
        document.documentElement.msRequestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen();
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      }
    }
  }
  setLanguage(text: string, lang: string, flag: string) {
    this.countryName = text;
    this.flagvalue = flag;
    this.langStoreValue = lang;
  }
  mobileMenuSidebarOpen(event: any, className: string) {
    const hasClass = event.target.classList.contains(className);
    if (hasClass) {
      this.renderer.removeClass(this.document.body, className);
    } else {
      this.renderer.addClass(this.document.body, className);
    }
  }
  callSidemenuCollapse() {
    const hasClass = this.document.body.classList.contains('side-closed');
    if (hasClass) {
      // this.renderer.removeClass(this.document.body, 'side-closed');
      // this.renderer.removeClass(this.document.body, 'submenu-closed');
      this.store.dispatch(SideNavOpen());
    } else {
      this.store.dispatch(SideNavClose());
      // this.renderer.addClass(this.document.body, 'side-closed');
      // this.renderer.addClass(this.document.body, 'submenu-closed');
    }
  }



  login() {
    this.authService.loginRedirect();
  }

  logout() {
    this.msalService.logoutRedirect();
  }
  changeSubscription(value: number, currentid: number) {
    console.log(currentid)
    let Subscription: UserSubscription = {
      recurrenceTypeId: value,
      ID: currentid != null ? currentid : 0,
      appUserID: this.currentUser.extension_BzUserId,
      subscriptionFor: 1,
      isSubscribed: true,
      createdDate: this.myDate,
      createdBy: this.currentUser.extension_BzUserId,
      modifiedDate: null,
      modifiedBy: null,
      isActive: true,
      RecurrenceType: ''
    }
    this.profileUpdating = true;
    this.profileService.updateSubscription(Subscription).subscribe({
      next: async (data) => {

        this.matSnackBar.open("Subscriptions updated Successfully", "", {
          duration: 3000,
          panelClass: 'bg-success'
        });
        let query: IProfileSettingsSearch = {
          userId: this.currentUser.extension_BzUserId
        };
        await this.headerstore.dispatch(LoadProfileSettings(query));
        this.profileUpdating = false;
      },
      error: (err) => {
        this.matSnackBar.open("Unable to update Subscriptions", "", {
          duration: 3000,
          panelClass: 'bg-danger'
        });
        this.profileUpdating = false;
      }
    })
  }
  updateLandingPage(id: number) {
    console.log(id);



    // var paramdata = JSON.stringify(landingpage);
    this.profileUpdating = true;
    this.profileService.updateLandingPage(this.currentUser.extension_BzUserId, id).subscribe({
      next: async (data) => {

        this.matSnackBar.open("Landing Page updated Successfully", "", {
          duration: 3000,
          panelClass: 'bg-success'
        });
        let query: IProfileSettingsSearch = {
          userId: this.currentUser.extension_BzUserId
        };
        await this.headerstore.dispatch(LoadProfileSettings(query));
        this.profileUpdating = false;
      },
      error: (err) => {
        this.matSnackBar.open("Unable to update Landing Page", "", {
          duration: 3000,
          panelClass: 'bg-danger'
        });
        this.profileUpdating = false;
      }
    })
  }
  profilestatus(value: number) {

    let status = value;
    let emailStatus = null;
    let phoneStatus = null;
    this.profileUpdating = true;
    this.profileService.updateProfileVisibleStatus(this.currentUser.extension_BzUserId, status, emailStatus, phoneStatus).subscribe({
      next: async (data) => {

        this.matSnackBar.open("Profile Status updated Successfully", "", {
          duration: 3000,
          panelClass: 'bg-success'
        });
        let query: IProfileSettingsSearch = {
          userId: this.currentUser.extension_BzUserId
        };
        await this.headerstore.dispatch(LoadProfileSettings(query));
        this.profileUpdating = false;
      },
      error: (err) => {
        this.matSnackBar.open("Unable to update Profile Status", "", {
          duration: 3000,
          panelClass: 'bg-danger'
        });
        this.profileUpdating = false;
      }
    })
  }
  emailStatusUpdate(curStatus: boolean) {

    let status = null;
    if (curStatus == true) {
      this.emailStatus = 0
    } else {
      this.emailStatus = 1
    }

    let phoneStatus = null;
    this.profileUpdating = true;
    this.profileService.updateProfileVisibleStatus(this.currentUser.extension_BzUserId, status, this.emailStatus, phoneStatus).subscribe({
      next: async (data) => {

        this.matSnackBar.open("Email Status updated Successfully", "", {
          duration: 3000,
          panelClass: 'bg-success'
        });
        let query: IProfileSettingsSearch = {
          userId: this.currentUser.extension_BzUserId
        };
        await this.headerstore.dispatch(LoadProfileSettings(query));
        this.profileUpdating = false;
      },
      error: (err) => {
        this.matSnackBar.open("Unable to update Email Status", "", {
          duration: 3000,
          panelClass: 'bg-danger'
        });
        this.profileUpdating = false;
      }
    })
  }
  phoneStatusUpdate(curStatus: boolean) {

    let status = null;
    let emailStatus = null;

    if (curStatus == true) {
      this.phoneStatus = 0;
    } else {
      this.phoneStatus = 1;
    }

    this.profileUpdating = true;
    this.profileService.updateProfileVisibleStatus(this.currentUser.extension_BzUserId, status, emailStatus, this.phoneStatus).subscribe({
      next: async (data) => {

        this.matSnackBar.open("Phone Status updated Successfully", "", {
          duration: 3000,
          panelClass: 'bg-success'
        });
        let query: IProfileSettingsSearch = {
          userId: this.currentUser.extension_BzUserId
        };
        await this.headerstore.dispatch(LoadProfileSettings(query));
        this.profileUpdating = false;
      },
      error: (err) => {
        this.matSnackBar.open("Unable to update Phone Status", "", {
          duration: 3000,
          panelClass: 'bg-danger'
        });
        this.profileUpdating = false;
      }
    })
  }

  changePassword() {
    this.msalService.loginRedirect({
      scopes: silentRequest.scopes,
      authority: environment.b2cPolicies.changePassword.authority
    })
  }
  discussionsTrackByFn(index: number, notification: UserTaggedNotificationDto) {
    return notification.tagId;
  }
  async readNotification(param: UserTaggedNotificationDto) {
    this.myDrop.close();
    console.log("param", param)
    let cUser = await this.authService.getCurrentUser();
    if (param.flagId != 0) {
      this.communityService.readNotifications(param).subscribe({
        next: async (data) => {
          this.headerstore.dispatch(LoadNotifications());
          if (param.typeName == 'Community') {
            var searchdata: CommunityDiscussionSearch = {
              communityId: param.id,
              searchKeyword: '',
              recentTopDiscussionID: 0,
              pageNumber: 1,
              pageSize: 5,
              communityDiscussionID: param.discussionId
            }


            this.store.dispatch(LoadCommunityDiscussionsData(searchdata));

            this.router.navigate(['/community/', this.slug.transform(param.name), param.id, 'discussions', param.discussionId]);
            // var url:string = "['/community',(param.name | slug),param.iD,'discussions',param.discussionId]";
            // var url: string = "/community/" + param.name + "/" + param.id + "/discussions/" + param.discussionId;
            // this.router.navigate([url]);
          } else if (param.typeName == 'Group') {

            var searchGroupdata: GroupDiscussionSearch = {
              GroupId: param.id,
              searchKeyword: '',
              recentTopDiscussionID: 0,
              pageNumber: 1,
              pageSize: 5,
              UserId: cUser?.extension_BzUserId,
              GroupDiscussionID: param.discussionId
            }


            this.store.dispatch(LoadGroupDiscussionsData(searchGroupdata));

            this.router.navigate(['/group/', this.slug.transform(param.name), param.id, 'discussions', param.discussionId]);

            // var url: string = "/group/" + param.name + "/" + param.id + "/discussions/" + param.discussionId;
            // this.router.navigate([url]);
          }
        }
      })
    } else {
      if (param.typeName == 'Community') {
        var searchdata: CommunityDiscussionSearch = {
          communityId: param.id,
          searchKeyword: '',
          recentTopDiscussionID: 0,
          pageNumber: 1,
          pageSize: 5,
          communityDiscussionID: param.discussionId
        }
        this.store.dispatch(LoadCommunityDiscussionsData(searchdata));

        var url: string = "/community/" + this.slug.transform(param.name) + "/" + param.id + "/discussions/" + param.discussionId;
        this.router.navigate([url]);
      } else if (param.typeName == 'Group') {
        var searchGroupdata: GroupDiscussionSearch = {
          GroupId: param.id,
          searchKeyword: '',
          recentTopDiscussionID: 0,
          pageNumber: 1,
          pageSize: 5,
          UserId: cUser?.extension_BzUserId,
          GroupDiscussionID: param.discussionId
        }


        this.store.dispatch(LoadGroupDiscussionsData(searchGroupdata));
        var url: string = "/group/" + this.slug.transform(param.name) + "/" + param.id + "/discussions/" + param.discussionId;
        this.router.navigate([url]);
      }
    }
  }


  getUserMembershipStatuses() {
    this.authService.getCurrentUser2().subscribe(claims => {
      if (claims != null) {
        // this.profileService.getUserMembershipStatuses().subscribe({
        //   next: (data) => {
        //     // if (data.length > 0) {
        //     //   this.userMembershipPlansStatus = data;
        //     //   this.isPlansExpiredOrAboutToExpire = true;
        //     //   let allExpired = data.every(item => item.alreadyExpired === true);
        //     //   let expiredItems = data.filter(item => item.alreadyExpired);
        //     //   let someButNotAllExpired = expiredItems.length > 0 && expiredItems.length < data.length;
        //     //   if (data.length == 1) {
        //     //     if (allExpired) {
        //     //       this.renewMessage = "Your community membership plan has expired! Renew now to continue availing features";
        //     //     }
        //     //     else {
        //     //       this.renewMessage = "Your community membership plan will expire in a few days! Renew now to continue availing features";
        //     //     }
        //     //   }
        //     //   else if (data.length > 1) {
        //     //     if (allExpired) {
        //     //       this.renewMessage = "All your community membership plans are about to expire/expired! Renew now to continue availing features";
        //     //     }
        //     //     else if (someButNotAllExpired) {
        //     //       this.renewMessage = "Few of your community membership plans are about to expire/expired! Renew now to continue availing features";
        //     //     }
        //     //     else {
        //     //       this.renewMessage = "Few of your community membership plans are about to expire! Renew now to continue availing features.";
        //     //     }
        //     //   }
        //     // }
        //     // else {
        //     //   this.userMembershipPlansStatus = [];
        //     //   this.isPlansExpiredOrAboutToExpire = false;
        //     // }
        //   },
        //   error: (error) => {
        //     console.error('Error fetching user membership statuses', error);
        //   },
        // });
        this.headerstore.select(loadMembershipStatussesData).subscribe((membershipStatussesdata) => {
          //console.log(data.notifications?.filter(s=>s.flagId == 1));
          var data = membershipStatussesdata?.data?.data;
          console.log(data,'data');
          if (data?.length > 0) {
            this.userMembershipPlansStatus = data;
            this.isPlansExpiredOrAboutToExpire = true;
            let allExpired = data.every(item => item.alreadyExpired === true);
            let expiredItems = data.filter(item => item.alreadyExpired);
            let someButNotAllExpired = expiredItems.length > 0 && expiredItems.length < data.length;
            if (data.length == 1) {
              if (allExpired) {
                this.renewMessage = "Your community membership plan has expired! Renew now to continue availing features";
              }
              else {
                this.renewMessage = "Your community membership plan will expire in a few days! Renew now to continue availing features";
              }
            }
            else if (data.length > 1) {
              if (allExpired) {
                this.renewMessage = "All your community membership plans are about to expire/expired! Renew now to continue availing features";
              }
              else if (someButNotAllExpired) {
                this.renewMessage = "Few of your community membership plans are about to expire/expired! Renew now to continue availing features";
              }
              else {
                this.renewMessage = "Few of your community membership plans are about to expire! Renew now to continue availing features.";
              }
            }
          }
          else {
            this.userMembershipPlansStatus = [];
            this.isPlansExpiredOrAboutToExpire = false;
          }
        })
      }
    })

  }

  renewMembershipNow() {
    if (this.userMembershipPlansStatus.length == 1) {
      const currentUtcDate = moment.utc();
      const expirtyDate = moment.utc(this.userMembershipPlansStatus[0].endDateComp);
      const differenceInDays = expirtyDate.diff(currentUtcDate, 'days');

      if (differenceInDays <= 0) {
        this.router.navigate(['communities', this.slug.transform(this.userMembershipPlansStatus[0]?.communityName), 'membership-plan', this.userMembershipPlansStatus[0]?.communityId]);
      }
      else {
        const dialogRef = this.dialog.open(AdminConfirmationDialogComponent, {
          width: '500px',
          backdropClass: 'customMatModalBackgroundColor',
          disableClose: true,
          hasBackdrop: true,
          data: {
            title: '',
            description: 'Your membership is set to expire in ' + differenceInDays + ' days. If you renew it now, the changes will take effect immediately. Would you like to proceed with renewing your membership plan now?',
            yesText: 'Yes, Proceed',
            noText: 'No, I will do it later'
          }
        });

        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            this.router.navigate(['communities', this.slug.transform(this.userMembershipPlansStatus[0]?.communityName), 'membership-plan', this.userMembershipPlansStatus[0]?.communityId]);
          }
        });
      }
    }
    else if (this.userMembershipPlansStatus.length > 1) {
      this.router.navigate(['communities/membership-expiry-communities']);
    }
  }

  /*Temporary Code*/
  ltlMenu() {
    const body = document.querySelector('body');
    this.renderer.removeClass(body, 'rtl-body-menu');
    this.renderer.removeClass(body, 'top-body-menu');
  }
  rtlMenu() {
    const body = document.querySelector('body');
    this.renderer.addClass(body, 'rtl-body-menu');
    this.renderer.removeClass(body, 'top-body-menu');
  }
  ttbMenu() {
    const body = document.querySelector('body');
    this.renderer.addClass(body, 'top-body-menu');
    this.renderer.removeClass(body, 'rtl-body-menu');
    if (this.router.url.startsWith('/admin')) {
      this.renderer.removeClass(body, 'top-body-menu');
    }
  }
  /*Temporary Code*/
}

export interface MenuItem {
  name: string;
  link: string;
  subMenu?: Array<MenuItem>;
}
export interface UserSubscription {
  ID: number;
  appUserID: number;
  subscriptionFor: number;
  recurrenceTypeId: number;
  isSubscribed: boolean;
  createdDate: Date;
  createdBy?: number;
  modifiedDate?: Date;
  modifiedBy?: number;
  isActive?: boolean;
  RecurrenceType: string;
}
export interface UserClaim {
  aud: string;
  iss: string;
  iat: number;
  nbf: number;
  exp: number;
  aio: string;
  email: string;
  family_name: string;
  given_name: string;
  name: string;
  nonce: string;
  oid: string;
  preferred_username: string;
  rh: string;
  sid: string;
  sub: string;
  tid: string;
  uti: string;
  ver: string;
}

function next(next: any, arg1: (data: any) => Promise<void>) {
  throw new Error('Function not implemented.');
}
